import React from 'react';
import loadable from '@loadable/component';

import Meta from '../src/Meta';
import CompaniesHeaderSection from '../src/companies/CompaniesHeaderSection';
import CompaniesFeaturesSection from '../src/companies/CompaniesFeaturesSection';
import AboutUsSection from '../src/AboutUsSection';

const CompaniesNavbar = loadable(() => import('../src/companies/CompaniesNavbar'));

export default function CompaniesLandingPage() {
  return (
    <div className="companies-landing-page">
      <Meta />
      <CompaniesNavbar />
      <CompaniesHeaderSection
        title="Automação para criação e edição de vídeo"
        description="Ganhe a produtividade na criação de conteúdos ou crie novas soluções baseadas nas nossas APIs para geração de vídeo."
        video="/images/sheet-to-videos.mp4"
      />
      <CompaniesFeaturesSection />
      <AboutUsSection dark />
      <style jsx>
        {`
        .companies-landing-page {
          --landing-page-desktop-padding: 80px;
          --landing-page-mobile-padding: 60px 20px;
          --gradient-red: linear-gradient(
            147deg, rgba(121,21,8,1) 0%,
            rgba(175,33,15,1) 19%,
            rgba(194,36,17,1) 39%,
            rgba(220,67,46,1) 85%,
            rgba(243,127,33,1) 100%
          );

          background: var(--color-layout-background);
          font-family: var(--theme-font-family);
        }
        `}
      </style>
    </div>
  );
}
